import {initializeApp} from "firebase/app";
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {initializeAppCheck, ReCaptchaEnterpriseProvider, onTokenChanged} from "firebase/app-check";
import {getAnalytics, logEvent} from "firebase/analytics";
import {getAuth, connectAuthEmulator, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// eslint-disable-next-line no-restricted-globals
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APPCHECK_KEY),
  isTokenAutoRefreshEnabled: true
});
onTokenChanged(appCheck, {
  error: (error) => {
    console.log("App Check Token error", error);
  },
  complete: () => {
    console.log("App Check Token complete");
  },
});

const auth = getAuth(app);
const authProvider = new GoogleAuthProvider();

const analytics = getAnalytics(app);
const logAnalyticsEvent = (event, context) => {logEvent(analytics, event, context)};

const {hostname} = window.location;
if (hostname === "localhost" || hostname === "127.0.0.1") {
  connectFirestoreEmulator(db, hostname, 5002);
  connectAuthEmulator(auth, `http://${hostname}:9099`);
}

export {
  db,
  auth,
  authProvider,
  logAnalyticsEvent,
};
