// import logo from './logo.svg';
import React, { useEffect } from 'react';

function Gallery(props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `new juicebox(${JSON.stringify(props)});`;
    script.async = false;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, [props]);
  
  return (
    <div id={props.containerId}></div>
  );
}

export default Gallery;
