import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { saveCandle, deleteCandle } from '../data/editCandle';

const defaultForm = () => ({
  message: "",
  translation: "",
  from: "",
  id: null,
});

const formValid = (form) => {
  const { message } = form;
  return message && message.trim().length > 0;
};

function EditCandle(props) {
  const {
    onHide: parentOnHide,
    candle: providedCandle,
  } = props;

  const [form, setForm] = useState(defaultForm());
  const [showModal, setShowModal] = useState(providedCandle !== null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const {isSaving, error} = useSelector((state) => state.saveCandle);

  useEffect(() => {
    if (providedCandle === null && form.id !== null) {
      setForm(defaultForm());
      setShowModal(false);
      setShowError(false);
    }
    if (providedCandle !== null && providedCandle.id !== form.id) {
      setForm({
        id: providedCandle.id,
        message: providedCandle.message,
        translation: providedCandle.translation,
        from: providedCandle.from,
      });
      setShowModal(true);
    }
  }, [providedCandle, form, setForm, setShowModal, setShowError]);

  const canSubmit = formValid(form);
  const canDelete = form.message === "";

  const handleSave = async () => {
    if ( !canSubmit ) {
      return;
    }

    setFormDisabled(true);
    dispatch(saveCandle(form));
  };

  const handleDelete = async () => {
    if ( !canDelete ) {
      return;
    }

    setFormDisabled(true);
    dispatch(deleteCandle(form));
  };

  useEffect(() => {
    if (!formDisabled) return;

    if (!isSaving) {
      if (error) {
        console.error("LightCandle returned error", error);
        setShowError(true);
      } else {
        parentOnHide();
      }
      setFormDisabled(false);
    }
  }, [formDisabled, setFormDisabled, isSaving, error, parentOnHide]);

  return (    
    <>
      <Modal
        show={showModal}
        onHide={parentOnHide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Edit candle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Alert hidden={!showError}>
              An unexpected error occurred.  Please try again.
            </Alert>
            <Form.Group
              className="mb-3"
              controlId="message"
              >
              <Form.Label>Message</Form.Label>
              <Form.Control 
                as="textarea"
                rows={4}
                value={form.message}
                onChange={e => setForm({ ...form, message: e.target.value })}
                disabled={formDisabled}
                id="candle-message"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="translation"
              >
              <Form.Label>Translation</Form.Label>
              <Form.Control 
                as="textarea"
                rows={4}
                value={form.translation}
                onChange={e => setForm({ ...form, translation: e.target.value })}
                disabled={formDisabled}
                id="candle-translation"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="from">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="text"
                placeholder="Anonymous"
                value={form.from}
                onChange={e => setForm({ ...form, from: e.target.value })}
                disabled={formDisabled}
                id="candle-from"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{flexGrow: 1}}>
            <Button 
              variant="danger"
              onClick={handleDelete}
              disabled={!canDelete}
              className="text-left"
            >
              Delete
            </Button>
          </div>
          <Button 
            variant="secondary"
            onClick={parentOnHide}
            disabled={formDisabled}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={formDisabled || !canSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditCandle;
