import {createSlice, createAction} from '@reduxjs/toolkit';
import { collection, deleteDoc, deleteField, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import {Observable} from 'rxjs';
import {switchMap, filter} from 'rxjs/operators';
import { dbConfig } from '../config';
import {db} from './firebase';

const candlesCollection = collection(db, dbConfig.collections.candles);

const initialState = {
    isSaving: false,
    candle: null,
    error: null,
};
export const saveCandleSlice = createSlice({
  name: 'saveCandle',
  initialState,
  reducers: {
    startSave: (state) => {
        state.isSaving = true;
        state.candle = null;
        state.error = null;
    },
    saveComplete: (state, action) => {
        const {payload: candle} = action;
        state.isSaving = false;
        state.candle = candle;
    },
    saveError: (state, action) => {
        const {payload: error} = action;
        state.isSaving = false;
        state.error = error;
    },
  },
});

export const {startSave, saveComplete, saveError} = saveCandleSlice.actions;

const saveCandle = createAction('saveCandle');
const saveCandleEpic = action$ => action$.pipe(
    filter(saveCandle.match),
    switchMap((action) => new Observable((subscriber) => {
        const {payload: candle} = action;
        const {
          id,
          message,
          translation,
          from,
          litOnMs,
        } = candle;

        (async () => {
            subscriber.next(startSave());
            try {
              const candleDoc = doc(candlesCollection, id);
              const newCandle = {
                message,
                translation: translation || deleteField(),
                from: from || deleteField(),
                lastUpdated: serverTimestamp(),
              };
              await updateDoc(candleDoc, newCandle);
              subscriber.next(saveComplete({
                ...newCandle,
                id,
                litOnMs,
              }));
            } catch (err) {
              subscriber.next(saveError(err));
            } finally {
              subscriber.complete();
            }
        })();
    })),
);

const deleteCandle = createAction('deleteCandle');
const deleteCandleEpic = action$ => action$.pipe(
    filter(deleteCandle.match),
    switchMap((action) => new Observable((subscriber) => {
        const {payload: candle} = action;
        const {
          id,
        } = candle;

        (async () => {
            subscriber.next(startSave());
            try {
              const candleDoc = doc(candlesCollection, id);
              await deleteDoc(candleDoc);
              subscriber.next(saveComplete(null));
            } catch (err) {
              subscriber.next(saveError(err));
            } finally {
              subscriber.complete();
            }
        })();
    })),
);

export {
    saveCandleEpic,
    saveCandle,
    deleteCandleEpic,
    deleteCandle,
};

export default saveCandleSlice.reducer;
