import React from 'react';
import Card from 'react-bootstrap/Card';

const dateFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
};

function Candle(props) {
    const {
        candle,
        onDoubleClick,
    } = props;

    if (!candle) {
        return;
    }

    return (
        <Card className="mb-1 bg-primary lit-candle" onDoubleClick={onDoubleClick} id={`candle-${candle.id}`}>
            <Card.Body className="p-2">
                <Card.Text>
                    {candle.message}
                    {candle.translation && <><span className="translation-header">Translation</span><span>{candle.translation}</span></>}
                    <br/>
                    <small>
                        <span className="blockquote-footer ps-3 pt-2 fs-6">
                            Candle lit on {new Date(candle.litOnMs).toLocaleDateString(undefined, dateFormatOptions)} by {candle.from || "Anonymous"}
                        </span>
                    </small>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default Candle;
