import React, {useEffect} from 'react';
import { logAnalyticsEvent } from "../data/firebase";

function AboutNina() {
  useEffect(() => {
    logAnalyticsEvent('select_content', {
      content_type: 'page',
      content_id: 'about-nina',
    });
  }, []);

  return (
    <>
      <h1 className="fw-light text-center mb-0" id="page-title">Nina Viktoria Hilt</h1>
      <h5 className="fw-light text-center mt-0">October 11, 2002 - July 2, 2005</h5>
      <img src="images/aboutnina_photo.jpg" className="float-end m-1 imgshadow rounded-3" alt="Nina Smiling" />
      <p>
        Nina was a petite girl with big brown eyes, a signature smile, and a way of capturing the hearts of most people. She had an adventurous spirit, rarely showing fear of physical activities. Because it is hard to describe Nina's unique personality in a single paragraph, I have put together this list of questions and answers.
      </p>
      <p>
        <span className="about-question">Where did you adopt Nina?</span> We first met Nina in an orphanage in Irkutsk, Russia in May 2003. (For those of you who do not know Russia, Irkutsk is in southern Siberia, just above the Mongolian border.) She was six months old and had a cold, so we had very little quality time with her. We went back to adopt Nina in January 2004, when she was sixteen months old. This time, she was in much better spirits. When I held her, I knew for certain she was my daughter.
      </p>
      <p>
        <span className="about-question">How did Nina communicate?</span> Nina was just starting to pick up Russian words when she joined our family, so she was always playing catch up with her vocabulary development. As a result, she tended to favor non-verbal communication methods, such as pointing. She also was quick to show her emotions - whether a laugh or cry. You certainly knew how she was feeling.
      </p>
      <p>
        <span className="about-question">How was her relationship with the family?</span> Nina was a Daddy's Girl. We had a close relationship and she certainly stole my heart. Her relationship with Peg was the opposite, and sometimes strained. Nina looked up to her big sister. Although they loved each other, they had a typical sibling relationship filled with love/hate moments. In my daughter's words, "Nina was a great little sister, but she copied me all the time and occasionally bit me." Our extended family welcomed Nina like any other member of the family - with open arms.
      </p>
      <p>
        <span className="about-question">What was the largest challenge Nina overcame?</span> Without question, her largest challenge was eating. When Nina first came home, she ate small amounts and seemed to horde her food. Being a petite girl, we were worried that she was not eating enough. With guidance from the doctor, we fed her high calorie foods to ensure that the little she ate was sufficient. This lasted for months, and then one day she just started eating. By spring of 2005, this little girl ate her own large meal, and then finished her big sister's leftovers - a dramatic change from a year before. She even started trying more diverse food. Once she stuck her fork in my hot sauce and tried it before I could react. She loved it so much that she always tried Daddy's food, especially when it had a little spice.
      </p>
      <p>
        <span className="about-question">What do you miss most about Nina?</span> The thing I miss the most is her "Monster Walk". She would walk slowly around a corner toward you, with her hands raised and palms facing you. Rotating her hands in opposite circles, she would moan "ooh" and "ahh" in a low voice, while moving closer. When she got close enough, all you had to do was yell "boo" and she would immediately turn and run, laughing as she disappeared around the corner. It still makes me smile when I think about it today.
      </p>
      <p>
        <span className="about-question">Was Nina violent or out of control?</span> I was an independent consultant, so I worked many hours during the time Nina was with us. As a result, my ex-wife spent more time with our daughters than I did. I did, however, spend most of my non-working time with my family. In addition, I had many conversations with my oldest daughter, family, friends, and ex-wife since Nina's death, so I feel I have a good understanding of what went on during my absence. Except for the occasional biting, which was not out of character for her age, she was not harmful to her older sister. She did not ever go after her sister with a fork or any other object. She did not destroy things in our house. She did not have violent temper tantrums. I never received any complaints from daycare or babysitters. Many of the "out of control" behaviors surfaced long after Nina's death. For example, Peg originally confessed that Nina was merely misbehaving that fateful day. Then, over a year and a half later in December 2007, it was reported that Nina spread feces on the wall and furniture that day. A few months later, in March 2008, it was reported that Nina attacked her sister with a fork. Although I cannot say for certain what happened that day, I have my doubts as to the validity of the claims, especially when they come out years after Nina's death.
      </p>
      <p>
        <span className="about-question">Did Nina have Reactive Attachment Disorder (RAD)?</span> The short answer is that we will never know. I did not know about RAD before Nina's death, so she was never tested, let alone diagnosed. I can only offer my non-expert insights. Nina did have obvious attachment problems with Peg, and some personality characteristics that could be associated with RAD, such as a sense of fearlessness and comfort with strangers. At the same time, she was able to bond extremely well with me, her sister, and other adult figures in her life. She was also able to give Peg kisses and hugs at times. More important, I remember a time shortly before her death when she cried for her Mom as Peg left the house. That is something I had never seen before, and a positive sign that she was starting to bond with Peg. If I objectively look at the past, I believe Nina had some attachment issues, but I do not think she was severe enough to be diagnosed with RAD.
      </p>
    </>
  );
}

export default AboutNina;
