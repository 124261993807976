import React, {useEffect} from 'react';
import Gallery from '../components/Gallery';
import { logAnalyticsEvent } from '../data/firebase';

function PhotoGallery() {
  useEffect(() => {
    logAnalyticsEvent('select_content', {
      content_type: 'page',
      content_id: 'photo-gallery',
    });
  }, []);

  return (<Gallery
    containerId="photo-gallery"
    galleryWidth="100%"
    galleryHeight="100%"
    backgroundColor="rgba(0,0,0,0)"
    captionBackColor="#fc346f"
    captionBackTopColor="#fc346f"
    expandedBackgroundColor="#fc346f"
    randomizeImages={true}
    enableLooping={true}
    enableAutoPlay={true}
    autoPlayOnLoad={true}
    displayTime="3"
    configUrl="gallery-config.xml"
    captionPosition="BELOW_IMAGE"
  />);
}

export default PhotoGallery;
