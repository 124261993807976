import {createSlice, createAction} from '@reduxjs/toolkit'
import {Observable} from 'rxjs';
import {switchMap, filter} from 'rxjs/operators';
import {db} from './firebase';
import {
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const initialState = {
    isSaving: false,
    message: null,
    error: null,
};
export const sendMessageSlice = createSlice({
  name: 'sendMessage',
  initialState,
  reducers: {
    startSave: (state) => {
        state.isSaving = true;
        state.message = null;
        state.error = null;
    },
    saveComplete: (state, action) => {
        const {payload: mail} = action;
        state.isSaving = false;
        state.message = mail;
    },
    saveError: (state, action) => {
        const {payload: error} = action;
        state.isSaving = false;
        state.error = error;
    },
  },
});

export const {startSave, saveComplete, saveError} = sendMessageSlice.actions;

const sendMessage = createAction('sendMessage');

const sendMessageEpic = action$ => action$.pipe(
    filter(sendMessage.match),
    switchMap((action) => new Observable((subscriber) => {
        const {payload} = action;
        const {
          name,
          email,
          message,
        } = payload;
        const contactInfo = {
          name,
          email,
          message,
          isTest: navigator.webdriver,
          lastUpdated: serverTimestamp(),
        }

        const save = async () => {
            subscriber.next(startSave());

            let timeoutId;
            try {
              const doc = await Promise.race([
                addDoc(collection(db, "messages"), contactInfo),
                new Promise((resolve, reject) => {
                  timeoutId = setTimeout(() => reject(new Error("timeout")), 5000);
                }),
              ]);
              subscriber.next(saveComplete({
                id: doc.id,
              }));
              document.dispatchEvent(new CustomEvent(
                "remember-nina.message-sent",
                {
                  detail: {
                    ...contactInfo,
                    id: doc.id,
                  },
                }
              ));
            } catch (err) {
              subscriber.next(saveError(err));
            } finally {
              clearTimeout(timeoutId);
              subscriber.complete();
            }
        };
        save();
    })),
);

export {
    sendMessageEpic,
    sendMessage,
};

export default sendMessageSlice.reducer;
