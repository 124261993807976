import React, { useCallback, useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AboutSite from './pages/AboutSite';
import PhotoGallery from './pages/PhotoGallery';
import AboutNina from './pages/AboutNina';
import ShowCandles from './pages/ShowCandles';
import {login, logout} from './data/auth';

import './App.css';
import LightCandle from './components/LightCandle';
import ContactMe from './components/ContactMe';
import { Link } from 'react-router-dom';
import {loadRecentCandles} from './data/loadCandles';
import { logAnalyticsEvent } from './data/firebase';
import { initAuth } from './data/auth';
import { Toast, ToastContainer } from 'react-bootstrap';
// import { Toast } from 'react-bootstrap';

const year = (new Date()).getFullYear();

const App = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [showLightCandle, setShowLightCandle] = useState(false);
  const [showContactMe, setShowContactMe] = useState(false);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const [litCandles, setLitCandles] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);

  useEffect(() => {
    dispatch(initAuth());
    dispatch(loadRecentCandles());
  }, [dispatch]);

  const handleLightCandleOpen = useCallback(() => {
    if (!showLightCandle) {
      logAnalyticsEvent('light_candle_begin');
      setShowLightCandle(() => true);
    }
  }, [showLightCandle]);

  const handleLightCandleClose = useCallback(() => {
    setShowLightCandle(() => false);
  }, []);

  const handleLightCandleSave = useCallback((candle) => {
    setLitCandles([
      ...litCandles,
      candle.id,
    ]);
    logAnalyticsEvent('light_candle_complete');
  }, [litCandles, setLitCandles]);

  const handleContactMeOpen = useCallback(() => {
    if (!showContactMe) {
      logAnalyticsEvent('contact_me_begin');
      setShowContactMe(() => true);
    }
  }, [showContactMe]);

  const handleContactMeClose = useCallback(() => {
    setShowContactMe(() => false);
  }, []);

  const handleContactMeSave = useCallback((message) => {
    setSentMessages([
      ...sentMessages,
      message.id,
    ]);
    logAnalyticsEvent('contact_me_complete');
  }, [sentMessages, setSentMessages]);

  const loginOrLogout = () => {
    dispatch( isLoggedIn ? logout() : login() );
  }

  const removeLitCandle = useCallback((id) => {
    setLitCandles(litCandles.filter((item) => item !== id));
  }, [litCandles, setLitCandles]);

  const removeSentMessage = useCallback((id) => {
    setSentMessages(sentMessages.filter((item) => item !== id));
  }, [sentMessages, setSentMessages]);

  return (
    <>
      <Navbar id="page-header" bg="primary" variant="dark" fixed="top" expand="md" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <img
              src={isLoggedIn?"images/logoAuth.png":"images/logo.png"}
              height="50"
              alt="Logo"
              onDoubleClick={loginOrLogout}
            />
            Remember Nina
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="navbar">
            <Nav className="me-auto">
              <LinkContainer to="/about-site">
                <Nav.Link href="/about-site" onClick={() => setExpanded(false)}>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about-nina" >
                <Nav.Link href="/about-nina" onClick={() => setExpanded(false)}>About Nina</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/photo-gallery">
                <Nav.Link href="/photo-gallery" onClick={() => setExpanded(false)}>Photos</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/show-candles">
                <Nav.Link href="/show-candles" onClick={() => setExpanded(false)}>Candles</Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={() => { setExpanded(false); handleContactMeOpen(); }}>Contact Me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="p-3">
        <ToastContainer position="top-end" className="p-3">
        {litCandles.map((id) => (
          <Toast
            key={id}
            id={'candle-toast-' + id}
            bg="primary"
            autohide delay={3000}
            onClose={() => removeLitCandle(id)}
          >
            <Toast.Header>
              <strong className="me-auto">Remember Nina</strong>
            </Toast.Header>
            <Toast.Body>Your candle has been lit.  It may take a few moments to be published.</Toast.Body>
          </Toast>
        ))}
        {sentMessages.map((id) => (
          <Toast
            key={id}
            id={'message-toast-' + id}
            bg="primary"
            autohide delay={3000}
            onClose={() => removeSentMessage(id)}
          >
            <Toast.Header>
              <strong className="me-auto">Remember Nina</strong>
            </Toast.Header>
            <Toast.Body>Your message has been sent.</Toast.Body>
          </Toast>
        ))}
        </ToastContainer>
        <Routes>
          <Route path="about-site" element={<AboutSite />} />
          <Route path="about-nina" element={<AboutNina />} />
          <Route path="photo-gallery" element={<PhotoGallery />} />
          <Route path="show-candles" element={<ShowCandles />} />
          <Route path="*" element={<Navigate to="/about-site" replace />} />
        </Routes>
      </Container>
      <LightCandle show={showLightCandle} onHide={handleLightCandleClose} onSave={handleLightCandleSave} />
      <ContactMe show={showContactMe} onHide={handleContactMeClose} onSave={handleContactMeSave} />
      <div className="fixed-bottom text-end p-0" id="light-candle-button">
        <a className="btn light-candle-button" href="#!" role="button" onClick={handleLightCandleOpen}>
          <img src="images/candle.gif" alt="Light a candle" />
          <div className="fs-6"><small>Light a<br/>Candle</small></div>
        </a><br/>
      </div>
      <div className="home-link-list pb-2" id="footer-link-list">
        <ul>
          <li><Link to="/about-site">Home</Link></li>
          <li><Link to="/about-nina">About Nina</Link></li>
          <li><Link to="/photo-gallery">Photo Gallery</Link></li>
          <li><Link onClick={handleLightCandleOpen}>Light a Candle</Link></li>
          <li><Link to="/show-candles">Show Candles</Link></li>
          <li><Link onClick={handleContactMeOpen}>Contact Me</Link></li>
        </ul>
      </div>
      <hr />
      <div className="ts-6 text-center my-2" id="footer-legal">
        &#169; {year} Christopher Hilt<br/>
        This site is protected by reCAPTCHA and the Google<br/>
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        &nbsp;and&nbsp;<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
      </div>
    </>
  );
}

export default App;
