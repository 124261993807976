import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewportList } from 'react-viewport-list';

import Candle from '../components/Candle';
import EditCandle from '../components/EditCandle';
import { logAnalyticsEvent } from '../data/firebase';

function ShowCandles() {
  useEffect(() => {
    logAnalyticsEvent('select_content', {
      content_type: 'page',
      content_id: 'show-candles',
    });
  }, []);

  const candles = useSelector((state) => state.candles.current);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const ref = useRef(null);

  const [candleToEdit, setCandleToEdit] = useState(null);
  const editCandle=useCallback((candle) => {
    if (isLoggedIn) {
      setCandleToEdit(candle);
    }
  }, [isLoggedIn, setCandleToEdit]);

  if ( !candles || candles.length === 0) {
    return "No candles";
  }

  const ids = {};
  candles.forEach(c => {
    const key = c.id;
    if (ids[key]) console.log(`Duplicate candle id ${key}`);
    ids[key] = true;
  });

  return (
    <>
      <h1 className="fw-light text-center mb-0" id="page-title">Candles Lit</h1>
      <h5 className="fw-light text-center mt-0">Remembering Nina</h5>
      <div className="scroll-container" ref={ref}>
        <ViewportList viewportRef={ref} items={candles}>
          {(candle) => (<Candle key={candle.id} candle={candle} onDoubleClick={() => editCandle(candle)} />)}
        </ViewportList>
      </div>
      <EditCandle candle={candleToEdit} onHide={() => setCandleToEdit(null)} />
    </>
  );
}

export default ShowCandles;
