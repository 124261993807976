import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Candle from '../components/Candle';
import { logAnalyticsEvent } from '../data/firebase';

function AboutSite(props) {
    const candles = useSelector((state) => state.candles.current);
    const [candle, setCandle] = useState();

    useEffect(() => {
        logAnalyticsEvent('select_content', {
            content_type: 'page',
            content_id: 'about-site',
        });
    }, []);

    useEffect(() => {
        if (candles && candles.length > 0) {
            const selectRandomCandle = () => setCandle(candles[Math.floor(Math.random() * candles.length)]);
            const intervalID = setInterval(selectRandomCandle, 5000);
            if (!candle) selectRandomCandle();
            return () => clearInterval(intervalID);
        }
    }, [candle, candles, setCandle]);

    return (
        <>
            <h1 className="fw-light text-center" id="page-title">About This Site</h1>
            <figure>
                <blockquote className="blockquote">
                    <div className="lead text-left py-1">
                        Many people know Nina from the tragic circumstances that surrounded her death on July 2, 2005.
                        Her story made worldwide headlines and only focused on the events that led to her death.  The
                        articles were based on the viewpoints of my ex-wife, and they fail to describe the daughter I knew.
                    </div>
                    <div className="lead py-1">
                        This website is my way of honoring Nina and remembering who she was. I welcome you to browse this
                        website and celebrate her unique spirit and life.  If you feel so inclined, please light a candle in
                        her honor.
                    </div>
                </blockquote>
                <figcaption className="blockquote-footer ps-3 pt-1">
                    Chris Hilt, Nina's Dad
                </figcaption>
            </figure>
            {candle && (
                <Candle candle={candle} />
            )}
        </>
    );
}

export default AboutSite;

