import {createSlice, createAction} from '@reduxjs/toolkit'
import {Observable} from 'rxjs';
import {switchMap, filter} from 'rxjs/operators';
import {db} from './firebase';
import {
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const initialState = {
    isSaving: false,
    candle: null,
    error: null,
};
export const lightCandleSlice = createSlice({
  name: 'lightCandle',
  initialState,
  reducers: {
    startSave: (state) => {
        state.isSaving = true;
        state.candle = null;
        state.error = null;
    },
    saveComplete: (state, action) => {
        const {payload: candle} = action;
        state.isSaving = false;
        state.candle = candle;
    },
    saveError: (state, action) => {
        const {payload: error} = action;
        state.isSaving = false;
        state.error = error;
    },
  },
});

export const {startSave, saveComplete, saveError} = lightCandleSlice.actions;

const lightCandle = createAction('lightCandle');

const lightCandleEpic = action$ => action$.pipe(
    filter(lightCandle.match),
    switchMap((action) => new Observable((subscriber) => {
        const {payload} = action;
        const {message, from} = payload;
        const candle = {
          message,
          from,
          litOn: serverTimestamp(),
          isTest: navigator.webdriver,
          lastUpdated: serverTimestamp(),
        };

        (async () => {
            subscriber.next(startSave());

            let timeoutId;
            try {
              const doc = await Promise.race([
                addDoc(collection(db, "new-candles"), candle),
                new Promise((resolve, reject) => {
                  timeoutId = setTimeout(() => reject(new Error("timeout")), 5000);
                }),
              ]);
              subscriber.next(saveComplete({
                id: doc.id,
              }));
              document.dispatchEvent(new CustomEvent(
                "remember-nina.candle-lit",
                {
                  detail: {
                    ...candle,
                    id: doc.id,
                  },
                }
              ));
            } catch (err) {
              subscriber.next(saveError(err));
            } finally {
              clearTimeout(timeoutId);
              subscriber.complete();
            }
        })();
    })),
);

export {
    lightCandleEpic,
    lightCandle,
};

export default lightCandleSlice.reducer;
