import {createSlice, createAction} from '@reduxjs/toolkit'
import {cachedCandles} from '../config';
import {Observable} from 'rxjs';
import {switchMap, filter} from 'rxjs/operators';
import {db} from './firebase';
import {
    collection,
    query,
    where,
    onSnapshot,
    Timestamp
} from "firebase/firestore";

const cachedCandlesTimestamp = new Timestamp(
    cachedCandles.timestamp.seconds,
    cachedCandles.timestamp.nanoseconds,
);

const initialState = {
    current: [...cachedCandles.candles],
};
export const candlesSlice = createSlice({
  name: 'candles',
  initialState,
  reducers: {
    updateCandles: (state, action) => {
      const {current: existingCandles} = state;
      const {payload: newCandles} = action;

      // Remove any dups
      const unique = [...new Map([...existingCandles, ...newCandles].map((c) => [c.id, c])).values()];

      // sort by litOn descending
      state.current = unique.sort((c1, c2) => c2.litOnMs - c1.litOnMs);
    },
    deleteCandles: (state, action) => {
        const {current: existingCandles} = state;
        const {payload: candlesToDelete} = action;
  
        // Remove candles if id is in the action list
        state.current = existingCandles.filter(c => !candlesToDelete.includes(c.id));
      },
    },
});

export const {updateCandles, deleteCandles} = candlesSlice.actions;

const loadRecentCandles = createAction('candles/loadRecent');

const candlesCollection = collection(db, "candles");
const lastUpdatedFilter = where("lastUpdated", ">", cachedCandlesTimestamp);
const isNotTestFilter = where("isTest", "==", false);

const loadCandlesEpic = action$ => action$.pipe(
    filter(loadRecentCandles.match),
    switchMap(() => new Observable((subscriber) => {
        const recentCandlesQuery =
            navigator.webdriver ?
            query(candlesCollection, lastUpdatedFilter):
            query(candlesCollection, lastUpdatedFilter, isNotTestFilter);
        ;

        const unsubscribe = onSnapshot(recentCandlesQuery, {
            next: (snapshot) => {
                const candlesToUpdate = [];
                const candlesToDelete = [];
                snapshot.docChanges().forEach((change) => {
                    const {doc} = change;
                    if (change.type === "removed") {
                        candlesToDelete.push(doc.id);
                    } else {
                        const { message, from, translation, litOn } = doc.data();
                        candlesToUpdate.push({
                            id: doc.id,
                            message,
                            translation,
                            from,
                            litOnMs: litOn.toMillis(),
                        });
                    }
                });
                if (candlesToUpdate.length > 0) {
                    subscriber.next(updateCandles(candlesToUpdate));
                }
                if (candlesToDelete.length > 0) {
                    subscriber.next(deleteCandles(candlesToDelete));
                }
            },
            error: (error) => {
                console.error("Recent candles query errored", error);
                subscriber.error(error);
            },
            complete: () => {
                console.warn("Recent candles query closed");
                subscriber.complete();
            },
        });
        return unsubscribe;
    })),
);

export {
    loadCandlesEpic,
    loadRecentCandles,
};

export default candlesSlice.reducer;
