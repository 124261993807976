import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {lightCandle} from "../data/lightCandle";

const defaultForm = () => ({
  message: "",
  from: "",
});

const formValid = (form) => {
  const { message } = form;
  return message && message.trim().length > 0;
};

function LightCandle(props) {
  const parentShow = props.show;
  const parentOnHide = props.onHide;
  const parentOnSave = props.onSave;

  const [form, setForm] = useState(() => defaultForm());
  const [formDisabled, setFormDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const {isSaving, error, candle} = useSelector((state) => state.lightCandle);

  const canSubmit = formValid(form);

  const handleSave = async () => {
    if ( !canSubmit ) {
      return;
    }

    setFormDisabled(true);
    dispatch(lightCandle(form));
  };

  const handleClose = useCallback(() => {
    setForm(() => defaultForm());
    setShowError(false);
    try { parentOnHide(); } catch (err) { console.error("LightCandle: error in parent onHide method", err) }
  }, [setForm, setShowError, parentOnHide]);

  useEffect(() => {
    if (!formDisabled) return;

    if (!isSaving) {
      if (error) {
        console.error("LightCandle returned error", error);
        setShowError(true);
      } else {
        if (parentOnSave) {
          parentOnSave(candle);
        }
        handleClose();
      }
      setFormDisabled(false);
    }
  }, [formDisabled, setFormDisabled, isSaving, error, candle, handleClose, parentOnSave]);

  return (    
    <>
      <Modal
        show={parentShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="modal-title">Light a candle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Alert hidden={!showError}>
              An unexpected error occurred.  Please try again later.
            </Alert>
            <Form.Group
              className="mb-3"
              controlId="candle-message"
              >
              <Form.Label>Message</Form.Label>
              <Form.Control 
                as="textarea"
                rows={4}
                value={form.message}
                onChange={e => setForm({ ...form, message: e.target.value })}
                disabled={formDisabled}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="candle-from">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="text"
                placeholder="Anonymous"
                value={form.from}
                onChange={e => setForm({ ...form, from: e.target.value })}
                disabled={formDisabled}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary"
            onClick={handleClose}
            disabled={formDisabled}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={formDisabled || !canSubmit}
          >
            Light Candle
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LightCandle;
