import {configureStore} from '@reduxjs/toolkit';
import {combineEpics, createEpicMiddleware} from 'redux-observable';
import {combineReducers} from 'redux';
import {catchError} from 'rxjs/operators';
import candles, { loadCandlesEpic } from './loadCandles';
import lightCandle, { lightCandleEpic } from './lightCandle';
import sendMessage, { sendMessageEpic } from './sendMessage';
import login, {loginEpic, logoutEpic, initAuthEpic} from './auth';
import saveCandle, { saveCandleEpic, deleteCandleEpic } from './editCandle';

const epics = [
  loadCandlesEpic,
  lightCandleEpic,
  sendMessageEpic,
  loginEpic,
  logoutEpic,
  initAuthEpic,
  saveCandleEpic,
  deleteCandleEpic,
];
const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: combineReducers({
    candles,
    lightCandle,
    sendMessage,
    login,
    saveCandle,
  }),
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(epicMiddleware),
});

export const rootEpic = (action$, store$, dependencies) =>
      combineEpics(...epics)(action$, store$, dependencies).pipe(
        catchError((error, source) => {
          console.error(error);
          return source;
        })
      );
epicMiddleware.run(rootEpic);

export default store;
