import {createSlice, createAction} from '@reduxjs/toolkit'
import {onAuthStateChanged, signInWithRedirect, getRedirectResult, signOut} from 'firebase/auth';
import {Observable} from 'rxjs';
import {switchMap, withLatestFrom, filter, concatWith} from 'rxjs/operators';
import {auth, authProvider} from './firebase';

const initialState = {
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    user: null,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state, action) => {
        state.isLoggingIn = true;
    },
    loginComplete: (state, action) => {
        state.isLoggingIn = false;
    },
    logoutStart: (state, action) => {
        state.isLoggingOut = true;
    },
    logoutComplete: (state, action) => {
        state.isLoggingOut = false;
    },
    updateUser: (state, action) => {
        const {payload: user} = action;
        state.user = user;
        state.isLoggedIn = user !== null;
    },
  },
});

export const {
    loginStart,
    loginComplete,
    logoutStart,
    logoutComplete,
    updateUser,
} = authSlice.actions;

const initAuth = createAction("initAuth");
const initAuthEpic = (action$) => action$.pipe(
    filter(initAuth.match),
    switchMap(() => {
        const processRedirect$ = new Observable((subscriber) => {
            (async () => {
                try {
                    subscriber.next(loginStart());
                    await getRedirectResult(auth);
                } finally {
                    subscriber.next(loginComplete());
                    subscriber.complete();
                }
            })();
        });
        const authChanges$ = new Observable((subscriber) => {
            const unsubscribe = onAuthStateChanged(auth, {
                next: (user) => subscriber.next(updateUser(
                    user ?
                    {
                        email: user.email,
                        displayName: user.displayName
                    } :
                    null
                )),
                error: (err) => {
                    console.log("Login auto state error", err);
                    subscriber.complete();
                },
                complete: () => subscriber.complete(),
            });
            return unsubscribe;
        });
        return processRedirect$.pipe(concatWith(authChanges$));
    }),
);

const login = createAction('login');
const loginEpic = (action$, state$) => action$.pipe(
    filter(login.match),
    withLatestFrom(state$),
    filter(([, state]) => !state.login.isLoggingIn && !state.login.isLoggedIn),
    switchMap(() => new Observable((subscriber) => {
        (async () => {
            subscriber.next(loginStart());
            await signInWithRedirect(auth, authProvider);
        })();
    })),
);

const logout = createAction('logout');
const logoutEpic = (action$, state$) => action$.pipe(
    filter(logout.match),
    withLatestFrom(state$),
    filter(([, state]) => !state.login.isLoggingOut),
    switchMap(() => new Observable((subscriber) => {
        (async () => {
            try {
                subscriber.next(logoutStart());
                await signOut(auth);
            } finally {
                subscriber.next(logoutComplete());
                subscriber.complete();
            }
        })();
    })),
);


export {
    loginEpic,
    login,
    logoutEpic,
    logout,
    initAuthEpic,
    initAuth,
};

export default authSlice.reducer;
