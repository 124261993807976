import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {sendMessage} from "../data/sendMessage";

const defaultForm = () => ({
  name: "",
  email: "",
  message: "",
});

const formValid = (form) => {
  const { name, email, message } = form;
  return name && name.trim().length > 0 &&
    email && email.trim().length > 0 &&
    message && message.trim().length > 0;
};

function ContactMe(props) {
  const parentShow = props.show;
  const parentOnHide = props.onHide;
  const parentOnSave = props.onSave;

  const [form, setForm] = useState(() => defaultForm());
  const [formDisabled, setFormDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const {isSaving, error, message} = useSelector((state) => state.sendMessage);

  const canSubmit = formValid(form);

  const handleSave = async () => {
    if ( !canSubmit ) {
      return;
    }

    setFormDisabled(true);
    
    dispatch(sendMessage(form));
  };

  const handleClose = useCallback(() => {
    setForm(() => defaultForm());
    setShowError(false);
    try { parentOnHide(); } catch (err) { console.error("ContactMe: error in parent onHide method", err) }
  }, [setForm, setShowError, parentOnHide]);

  useEffect(() => {
    if (!formDisabled) return;

    if (!isSaving) {
      if (error) {
        console.error("SendMessage returned error", error);
        setShowError(true);
      } else {
        if (parentOnSave) {
          parentOnSave(message);
        }
        handleClose();
      }
      setFormDisabled(false);
    }
  }, [formDisabled, setFormDisabled, isSaving, error, message, handleClose, parentOnSave]);

  return (    
    <Modal
      show={parentShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="modal-title">Contact Me</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Alert hidden={!showError}>
            An unexpected error occurred.  Please try again later.
          </Alert>
          <Form.Group className="mb-3" controlId="contact-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={form.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
              disabled={formDisabled}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="contact-email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email address"
              value={form.email}
              onChange={e => setForm({ ...form, email: e.target.value })}
              disabled={formDisabled}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="contact-message"
            >
            <Form.Label>Message</Form.Label>
            <Form.Control 
              as="textarea"
              rows={3}
              value={form.message}
              onChange={e => setForm({ ...form, message: e.target.value })}
              disabled={formDisabled}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary"
          onClick={handleClose}
          disabled={formDisabled}
        >
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSave}
          disabled={formDisabled || !canSubmit}

        >
          Send Message
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactMe;
